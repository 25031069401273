.App {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
}

label, input, button {
  margin: 10px;
  font-size: 2rem;
}

button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff;
  border: none;
  color: white;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
